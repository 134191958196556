<template>
    <div class="video-page">
        <div class="cont">
            <div class="wid1200">
                <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
            </div>
            <div :style="{minHeight:minH}">
                <h5 class="title">
                    <span>{{pageName}}</span>
                </h5>
                <div class="list">
                    <ul>
                        <li v-for="item in latestVideo" :key="item.id">
                            <!-- <video :src="item.mediaPath" @click="goVideo(item)"  >
                                您的浏览器不支持 video 标签。
                                </video> -->
                            <img :src="item.titleImg" alt="" @click="goVideo(item)"  />
                            <h6 @click="goVideo">{{item.title}}</h6>
                            <!-- <p class="date" v-if="item.releaseDate">
                                <img src="../assets/img/oa/time.png" alt="" />
                                {{item.releaseDate}}
                            </p> -->
                        </li>
                    </ul>
                </div>
                <div class="page_box">
                    <el-pagination @current-change="handleCurrentChange" :current-page="currentPage"
                        :page-size="pageSize" layout="prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import BreadCrumb from '../components/BreadCrumb'

export default {
    components: {
        BreadCrumb
    },
    data() {
        return {
            pN: '',
            pageName: '视频列表',
            latestVideo: [],
            minH: '',
            currentPage: 1,
            pageSize: 15,
            total: 0,
            videoId: ''
        }
    },
    methods: {
        handleCurrentChange(val) {
            this.currentPage = val
            this.getLatestVideo()
        },
        goVideo(item) {
            if (item.jumpUrl) {
                window.open(item.jumpUrl, '_blank')
            } else if (item.originUrl) {
                window.open(item.originUrl, '_blank')
            } else {
                this.$router.push({ path: '/vD/v', query: { id: item.id, pN: encodeURI(this.pageName) } })
            }
        },
        golink(item) {
            // var routerData = this.$router.resolve({
            //     name: 'infoDetail',
            //     params: {
            //         id: id
            //     }
            // })

            // window.open(routerData.href, '_blank')
            if (item.jumpUrl) {
                window.open(item.jumpUrl, '_blank')
            } else if (item.originUrl) {
                window.open(item.originUrl, '_blank')
            } else {
                this.$router.push({path: '/nD/n', query: {id: item.id, pN: encodeURI(this.pageName)}})
            }
            
        },
        getLatestVideo() {
            if (this.videoId) {
                this.getAllData()
            } else {
                this.getData()
            }
        },
        getAllData() {
            var _this = this
            _this.$http
                .get('/admin/api/news/list', {
                    params: {
                        pageSize: 15,
                        pageNum: this.currentPage,
                        innerCode: this.videoId
                    }
                })
                .then(res => {
                    this.latestVideo = res.data.data.rows
                    this.total = res.data.data.total
                })
        },
        getData() {
            var _this = this
            _this.$http
                .get('/admin/api/news/list', {
                    params: {
                        pageSize: 15,
                        pageNum: this.currentPage
                    }
                })
                .then(res => {
                    this.latestVideo = res.data.data.rows
                    this.total = res.data.data.total
                })
        }
    },
    created() {
        this.minH = document.documentElement.clientHeight - 538 + 'px'
        if (this.$route.query.title) {
            this.pageName = this.$route.query.title
        }
        if (this.$route.query.id) {
            // this.pN = decodeURI(this.$route.query.pN)
            this.videoId = this.$route.query.id
        } else {
            this.videoId = ''
        }
        if (this.$route.query.pN) {
            this.pN = decodeURI(this.$route.query.pN)
        } else {
            this.pN = ''
        }
        this.getLatestVideo()
    }
}
</script>
<style scoped>
.back_box {
    width: 1200px;
    margin: 0 auto;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
}
.cont {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 50px;
}
.braed {
    margin: 15px 0;
}
.braed >>> .el-breadcrumb__inner.is-link:hover {
    color: #333;
    cursor: pointer;
}
.title {
    border-left: 6px solid #BD1A2D;
    font-size: 16px;
    line-height: 32px;
    font-weight: bold;
    margin-bottom: 12px;
    color: #292929;
}
.title > span {
    display: inline-block;
    margin-left: 1px;
    text-indent: 10px;
    border-left: 4px solid #BD1A2D;
    font-size: 20px;
    line-height: 33px;
}
 
    /*line-height: 48px;*/
.list ul {
    display: flex;
    flex-wrap: wrap;
}
.list ul li {
    width: 220px;
    margin-right: 24px;
    margin-bottom: 20px;
}
.list ul li:nth-child(5n+5) {
    margin-right: 0;
}
.list ul li img {
    width: 100%;
    height: 125px;
    cursor: pointer;
}
.list ul li h6 {
    width: 100%;
    padding: 0 10px 10px;
    font-size: 14px;
    color: #333;
    text-align: center;
    cursor: pointer;
}
            
        
    


.page_box {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    align-items: center;
    flex-wrap: nowrap;
}
.el-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
